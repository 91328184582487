import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout";

const Discounts = ({ data }) => {
  return (
    <Layout pageTitle="Travel & Accomodation Discounts">
      {data.allMdx.nodes.map((node) => (
        <article key={node.id}>
          <h2>
            <Link
              style={{ color: "#4a71b6", textDecoration: "none" }}
              to={`/discounts/${node.slug}`}
            >
              {node.frontmatter.title}
            </Link>
          </h2>
        </article>
      ))}
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___title, order: ASC } filter: { frontmatter: { category: { eq: "Discount" } } }) {
      nodes {
        frontmatter {
          title
          link
        }
        id
        slug
      }
    }
  }
`;

export default Discounts;
